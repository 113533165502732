:root { 
  --title-green: #050;
  --title-gray: #555;
  --dark-green: #003800;
  --gray: #ccc;
}

@font-face {
	font-family: Play;
	src: url(../public/play.woff);
	font-weight: normal;
}

@keyframes loading {
  0% { background-position: 0% 0%; }
  50% { background-position: -50% -50%; }
  100% { background-position: -100% -100%; }
}

@keyframes highlight {
  70% { background-color: #555500; }
  100% { background-color: #444400; }
}

@keyframes flash {
  50% { background-color: rgba(255, 255, 0, 0.15); }
}

@keyframes flashInvalid {
  50% { border-color: #ff5555 }
}

.contact::after {
  content: attr(data-dom);
}
.contact::before {
  content: attr(data-box) "\0040";
}
.inline-block {
  display: inline-block;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.loading { 
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0), rgba(0, 255, 0, 0.3), rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0));
  background-size: 800% 800%;
  animation: loading 1s linear infinite;
  min-width: 35px;
  min-height: 16px;
}

.loading-img { 
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0), rgba(0, 255, 0, 0.3), rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0));
  background-size: 800% 800%;
  animation: loading 1s linear infinite;
  min-width: 35px;
  min-height: 16px;
}

.logging-in { 
  animation: flash 1s linear infinite;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.vl {
  border-left: 2px solid;
  border-image: linear-gradient(90deg, rgba(255, 255, 255, 0.0) 0%, rgba(255, 255, 255, 1.0) 50%, rgba(255, 255, 255, 0.0) 100%) 1 100%;
  height: 32px;
}

body {
  margin: 0;
  font-family: Play;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
	color: rgba(0, 255, 0, 1.0);
}

.sf-text {
  color: var(--gray);
}

.sf-version {
  font-size: 12px;
  text-align: left;
}

.color-ddd {
  color: #ddd;
}

.top-container {
  min-height: calc(100vh - 134px);
  margin-top: 60px;
}

.navbar-container {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
  background-color: rgba(0, 100, 0, 0.7);
  box-shadow: 0px 0px 5px #000;
  backdrop-filter: blur(6px);
}

.footer-container {
  display: flex;
  z-index: 1;
  background-color: var(--dark-green);
  margin-top: 24px;
  box-shadow: 0px 0px 5px #000;
  height: 50px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-shadow: 1px 1px 0px black;
}

.footer-container div {
  margin: 0px 8px 0px 8px;
}

.footer-container img {
  margin-top: 4px;
  width: 36px;
  height: 36px;
  filter: drop-shadow(2px 2px 0px black);
}

.footer-img img {
  border-radius: 8px;
}

.footer-left {
  flex: 1;
}

.footer-right {
  flex: 1;
}

.page-container {
  padding-top: 84px;
  font-family: Play;
  min-height: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.page-title-cont {
  padding: 1px;
  border-radius: 5px;
  background-color: var(--title-green);
  box-shadow: 0 0 12px #000000;
  margin-bottom: 24px;
}

.page-title {
  font-size: 25px;
}

.page-header-cont {
  padding: 1px;
  border-radius: 5px;
  background-color: var(--dark-green);
  box-shadow: 0 0 12px #000000;
  margin-bottom: 24px;
}

.page-header {
  font-size: 18px;
}

.img-container {
  width: 100%;
  height: 0;
  position: relative;
}

.page-banner {
  max-height: 485px;
}

.page-banner img {
  display: block;
  width: 100%;
  max-height: 485px;
  object-fit: cover;
  object-position: 0px 0px;
}

.page-content {
  display: flex;
  width: 100%;
  justify-content: center;
}

.game-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.navbar-items {
  max-width: 1000px;
  text-align: center;
	display: flex;
	justify-content: left;
	align-items: center;
	flex-flow: wrap;
	font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}

.navbar-links {
  width: 550px;
  text-align: center;
	display: flex;
	justify-content: left;
	align-items: center;
	flex-flow: row;
	font-weight: bold;
}

.navbar-logo {
  width: 270px;
  height: 64px;
  padding: 0px 6px 0px 6px;
}

.navbar-logo-bright:hover {
  filter: brightness(125%);
}

.navbar-title {
	display: flex;
}

.navbar-title:focus {
	outline-width: 0;
}

.nav-link {
	color: unset;
	text-decoration: none;
}

.navbar-link {
  display: flex;
  height: 60px;
  align-items: center;
  text-shadow: 2px 2px 0px black;
}

.navbar-link-login {
  display: flex;
  align-items: center;
}

.navbar-expand {
  user-select: none;
  font-size: 40px;
  padding: 0px 16px 0px 16px;
}

.navbar-expand:hover {
  cursor: pointer;
}

.navbar-item {
  display: flex;
	font-size: 22px;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: 0.2s;
}

.navbar-item:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.navbar-item:hover a {
  color: #fff;
}

.navbar-item a {
  color: #ddd;
	text-decoration: none;
  text-align: left;
  padding: 0px 12px 0px 12px;
}

.navbar-item-login {
  height: 60px;
  max-width: 180px;
  margin: 0px 0px 0px auto;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navbar-item-login-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 156px;
}

.navbar-item-login a {
	color: #CCCCCC;
  font-weight: normal;
}

.navbar-item-yt {
	margin-top: 7px;
}

.navbar-drop {
  z-index: 1;
  display: grid;
  position: absolute;
  top: 60px;
  opacity: 0;
  background-color: var(--title-green);
  padding: 5px;
  min-width: 100px;
  font-size: 16px;
  font-weight: normal;
  box-shadow: 0 0 12px #000000;
  transition-property: opacity;
  transition-duration: 0.2s;
  pointer-events: none;
  backdrop-filter: blur(6px);
}

.navbar-drop a {
  padding: 5px;
}

.navbar-drop-link {
  display: flex;
  align-items: center;
}

.navbar-drop-link:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.navbar-item:hover .navbar-drop {
  opacity: 1;
  pointer-events: auto;
}

.cont {
  overflow-wrap: break-word;
  background-color: var(--title-green);
  border-radius: 5px;
  box-shadow: 0 0 12px #000000;
  margin-bottom: 24px;
}

.cont-dark {
  background-color: var(--dark-green);
}

.cont:last-child {
  margin-bottom: 0px;
}

.cont h2:first-child {
  margin-top: 0px;
}

.col-cont {
  display: flex;
  text-align: left;
}

.col-1 {
  min-width: 744px;
  margin: 0px 12px 0px 0px;
}

.col-2 {
  min-width: 232px;
  margin: 0px 0px 0px 12px;
}

.col-title {
  text-align: center;
  font-size: 24px;
  padding: 12px;
  box-shadow: 0 8px 12px -12px black;
} 

.col-header {
  font-weight: bold;
  box-shadow: 0 0 12px #000000;
  background-color: var(--title-green);
  padding: 8px 0px 8px 0px;
  text-align: center;
  margin-bottom: 12px;
}

.col-div {
  padding: 16px;
  border-bottom: 2px solid black;
  background-color: var(--dark-green);
}

.col-div-highlight {
  animation: highlight 0.5s ease-out forwards;
}

.col-div:last-child {
  border-bottom: none;
}

.col-div div:last-child {
  margin-bottom: 0px;
}

.col-buttons {
  display: flex;
  justify-content: space-evenly;
}

.col-section {
  margin-bottom: 6px;
}

.news-cont:last-child .timeline {
  display: none;
}

.news-item {
  background-color: var(--dark-green);
}

.news-time {
  color: var(--gray);
  font-style: italic;
  text-align: right;
  padding: 0px 12px 12px 0px;
}

.news-title {
  background-color: var(--title-green);
  font-size: 24px;
  padding: 12px;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0 8px 12px -12px black;
}

.news-data {
  background-color: var(--dark-green);
  padding: 12px;
}

.news-video {
  padding: 0px 12px 12px 12px;
}

.news-button {
  display: block;
  text-align: left;
  margin-top: 14px;
  padding-left: 12px;
}

.news-button a {
  color: white;
  text-decoration: none;
  text-align: center;
  transition-property: border-color, background-color;
  transition-duration: .2s;
  font-size: 26px;
  background-color: var(--title-green);
  border: 2px outset #070;
  border-radius: 5px;
  box-shadow: 0 0 6px #000;
  padding: 4px 12px 4px 12px;
  outline: 0;
}

.news-button a:hover {
	outline-width: 0;
  border: 2px outset #f9ff56;
  cursor: pointer;
  background-color: var(--title-green);
}

.news-button a:focus {
	outline-width: 0;
  border: 2px outset #f9ff56;
}

.stats-bar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-basis: 100%;
  background-color: var(--title-green);
  height: 48px;
  box-shadow: 0px 0px 5px #000;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  line-height: normal;
  text-shadow: 1px 1px 0px black;
}

.stats-bar img {
  height: 12px;
  margin-right: 6px;
  filter: drop-shadow(1px 1px 0px black);
}

.downloads-bar {
  display: flex;
  flex-basis: 100%;
  line-height: normal;
  margin-top: 8px;
}

.downloads-bar img {
  height: 12px;
  margin-right: 6px;
  filter: drop-shadow(1px 1px 0px black);
}

.news-section {
  text-align: center;
}

.title-section {
  font-size: 48px;
  padding: 16px;
}

.subtitle-section {
  font-size: 24px;
  padding: 0px 0px 16px;
}

.text-section {
  font-size: 18px;
  padding: 0px 0px 16px 0px;
}

.featured-news {
  
}

.featured-news-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 336px;
}

.featured-news-left {
  margin: 24px;
  max-width: 512px;
  flex-basis: 512px;
}

.featured-news-right {
  margin: 24px;
  flex-basis: 512px;
}

.featured-news-img {
  height: 288px;
}

.featured-news-right img {
  width: 100%;
  border-radius: 16px;
}

.featured-news-title {
  font-size: 24px;
  font-weight: bold;
}

.featured-news-content {
  margin-top: 36px;
}

.mod-details {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.mod-details ul {
  margin: 0px 0px 16px 0px;
}

.mod-details-left {
  max-width: 640px;
  padding: 12px;
  margin: 12px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.15);
  flex-basis: 768px;
}

.mod-details-right {
  max-width: 640px;
  padding: 12px;
  margin: 12px;
  align-self: baseline;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.15);
  flex-basis: 352px;
}

.cards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1280px;
  width: 100%;
}

.cards-item {
  margin: 16px;
  filter: drop-shadow(0px 0px 6px black);
}

.cards-item img {
  display: block;
  width: 100%;
  border-radius: 16px;
  transition: filter 0.2s linear;
}

.cards-item:hover img {
  filter: brightness(125%);
}

.cards-item:hover .card-overlay {
  display: flex;
  opacity: 1.0;
}

.card-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 100%;
  opacity: 0.0;
  position: absolute;
  transform: translateY(164px);
  font-weight: bold;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
  font-size: 24px;
  color: white;
  border-radius: 0px 0px 16px 16px;
  transition-property: opacity;
  transition-duration: 0.2s;
  text-shadow: 2px 2px 0px black;
}

.icon-16 {
  margin-right: 4px;
  height: 16px;
}

.icon-21 {
  margin-right: 4px;
  display: inline-block;
  height: 21px;
  vertical-align: text-bottom;
}

.dots {
  overflow-wrap: normal;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  margin: 24px;
}

.video-cont {
  background-color: var(--dark-green);
  border-radius: 5px;
  box-shadow: 0 0 12px #000000;
  padding: 15px;
}

.video-cont-nobg {
  background-color: none;
  box-shadow: none;
}

.game-wrap {
  position: relative;
  background-size: cover;
  border-radius: 16px;
  border-radius: 8px;
  width: 640px;
  height: 484px;
}

.video-wrap {
  position: relative;
  padding-bottom: 56.25%;
  background-size: cover;
  border-radius: 16px;
  border-radius: 8px;
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.video-consent {
  display: flex;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 16px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 8px;
  text-shadow: 0px 0px 5px black, 0px 0px 5px black, 0px 0px 5px black;
}

.button-link {
  display: inline-block;
  font-size: 26px;
  text-align: center;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: .3s;
  color: white;
	text-decoration: none;
  background-color: #003200;
  border-radius: 5px;
  border-style: none;
  margin: 10px;
  padding: 6px;
  box-shadow: 0 0 12px #000000;
}

.button-link:hover {
  background-color: var(--title-green);
}

.margin-bottom {
  margin-bottom: 24px;
}

.margin-top {
  margin-top: 24px;
}

.padding-6 {
  padding: 6px;
}

.padding-12 {
  padding: 12px;
}

.padding-16 {
  padding: 16px;
}

.padding-24 {
  padding: 24px;
}

.padding-6-hor {
  padding: 6px 0px 6px 0px;
}

.padding-6-ver {
  padding: 0px 6px 0px 6px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-48 {
  font-size: 48px;
}

.width-1024 {
  max-width: 1024px;
}

.about {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.guide {
  max-width: 1024px;
  margin: 0 auto;
  text-align: left;
}

.guide-contents {
  flex-basis: 100%;
}

.guide-additional {
  background-color: rgba(0, 0, 0, 0.15);
  color: #ddd;
  font-size: 16px;
  margin: 8px;
  padding: 16px;
  border-radius: 16px;
}

.guide-step {
  margin: 16px 0px 16px 0px;
}

.guide-step .img-container {
  margin: 16px 0px 0px 0px;
}

.guide-step .img-container img {
  box-shadow: 0 0 8px #000000;
}

.guide-step:last-child {
  margin: 16px 0px 0px 0px;
}

.guide-img {
  border-radius: 16px;
}

.general {
  max-width: 1024px;
  margin: 12px;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 16px;
}

.general:target {
  animation: flash 1.5s ease;
}

.shadow {
  box-shadow: 0 0 12px #000000;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.img-resize {
  margin-top: 16px;
  max-width: 95%;
  border-radius: 16px;
}

.img-margin {
  margin: 16px 0px 16px 0px;
}

.timeline {
  display: inline-block;
  width: 2px;
  height: 30px;
  background-color: #555;
  margin: 10px 0px 10px 0px;
}

input[type=submit] {
  height: auto;
}

input:focus {
	outline-width: 0;
}

.game {
  width: 648px;
  height: 484px;
}

.game-transfer {
  width: 648px;
  height: 60px;
}

.icon-click {
  width: 64px;
  margin: 6px;
  transition-property: border-color;
  transition-duration: .2s;
  border: 2px outset var(--title-green);
  box-shadow: 0 0 12px #000000;
}

.icon-click:hover {
  border: 2px outset #f9ff56;
  cursor: pointer;
}

.content-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 600px;
}

.content-list a {
  color: white;
	text-decoration: none;
  display: block;
}

.mod-title {
  font-size: 24px;
  padding-bottom: 12px;
}

.mod-version {
  margin: 12px 0px;
  font-size: 16px;
  color: var(--gray);
}

.mod-checksum {
  margin: 12px 0px;
  font-size: 14px;
  color: var(--gray);
}

.mod-div {
  padding: 16px;
  background-color: var(--dark-green);
  text-align: left;
}

.mod-div:target {
  animation: flash 1.5s ease-in-out;
}

.mod-button {
  width: 100%;
}

.mod-button a {
  display: block;
  color: white;
  text-decoration: none;
  text-align: center;
  transition-property: border-color, background-color;
  transition-duration: .2s;
  font-size: 26px;
  background-color: var(--title-green);
  border: 2px outset #070;
  border-radius: 5px;
  box-shadow: 0 0 6px #000;
  padding: 4px 12px 4px 12px;
  outline: 0;
}

.mod-button-disabled a {
  display: block;
  color: white;
  text-decoration: none;
  text-align: center;
  transition-property: border-color, background-color;
  transition-duration: .2s;
  font-size: 26px;
  background-color: var(--title-gray);
  border: 2px outset #070;
  border-radius: 5px;
  box-shadow: 0 0 6px #000;
  padding: 4px 12px 4px 12px;
  outline: 0;
}

.mod-button a:hover {
	outline-width: 0;
  border: 2px outset #f9ff56;
  cursor: pointer;
  background-color: var(--title-green);
}

.mod-button a:focus {
	outline-width: 0;
  border: 2px outset #f9ff56;
}

.mod-section {
  margin-bottom: 16px;
}

.mod-section ul {
  margin-top: 0px;
}

.mod-section:last-child {
  margin-bottom: 0px;
}

.game-container {
  border: 6px outset #008800;
  transition-property: border-color;
  transition-duration: .2s;
  box-shadow: 0 0 12px #000000;
  width: 250px;
  height: 200px;
  margin: 24px;
  border-radius: 5px;
}

.game-container:hover {
  border: 6px outset #cccc00;
}

.game-container:hover .game-overlay {
  display: block;
  opacity: 1.0;
}

.game-overlay {
  opacity: 0.0;
  position: relative;
  bottom: 30px;
  font-weight: bold;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
  font-size: 23px;
  transition-property: opacity;
  transition-duration: 0.2s;
}

.form-title {
  font-size: 48px;
}

.form-login {
  width: 300px;
  margin-top: 20px;
  padding: 24px;
  box-shadow: 0 0 12px #000000;
  background-color: var(--dark-green);
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.form-login-msg {
  background-color: var(--dark-green);
  animation: flash 1s ease-in-out;
}

.form-login label {
  font-size: 18px;
}

.form-login-button {
  text-align: center;
}

.form-input {
	color: white;
	font-size: 16px;
  width: 284px;
	margin: 0px 0px 16px 0px;
	background: rgba(0, 0, 0, 0.5);
  padding: 6px;
  border: 2px outset var(--title-green);
  border-radius: 5px;
	-webkit-appearance: none;
	appearance: none;
	text-transform: none;
  outline: none;
  transition-property: border-color;
  transition-duration: 0.1s;
  font-family: Play;
}

.form-input-right {
  display: inline-block;
  position: absolute;
  margin: 6px;
}

.form-input:last-of-type {
  margin: 0px;
}

.form-input-long {
	width: 552px;
}

.form-input:focus {
	outline-width: 0;
  border: 2px outset #f9ff56;
}

.form-input:invalid {
	/*animation: "flashInvalid" 1s ease-in-out;*/
}

.form-link {
  font-size: 14px;
  cursor: pointer;
  color: rgba(0, 255, 0, 1.0);
}

.form-register {
  font-size: 16px;
  margin-top: 16px;
  cursor: pointer;
  color: rgba(0, 255, 0, 1.0);
}

.float-right {
  float: right;
}

.form-button {
  width: 200px;
  font-size: 26px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 6px;
  border: 2px outset var(--title-green);
  border-radius: 5px;
	-webkit-appearance: none;
  appearance: none;
	text-transform: none;
  outline: none;
  transition-property: border-color;
  transition-duration: 0.1s;
}

.form-button:hover {
	outline-width: 0;
  border: 2px outset #f9ff56;
  cursor: pointer;
}

.form-button:focus {
	outline-width: 0;
  border: 2px outset #f9ff56;
}

.button-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
}

.button-list-item {
  color: #fff;
  text-decoration: none;
  width: 100%;
  max-width: 768px;
  height: 30px;
  padding: 5px;
  font-size: 20px;
  border-radius: 5px;
  background-color: #004400;
  border: 2px solid #004400;
  box-shadow: 0 0 12px #000000;
  transition-property: border-color;
  transition-duration: 0.1s;
  user-select: none;
}

.button-list-item:hover {
  border: 2px solid #f9ff56;
}

.form-button-small {
  width: 40px;
}

.transfer-textarea {
  background-color: #003200;
  border: none;
  color: white;
  width: 648px;
  height: 200px;
  resize: none;
}

.fade-in-section {
  opacity: 0;
  transform: scaleX(0);
  visibility: hidden;
  transition: opacity 1.2s ease-out, transform 0.6s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.notify {
	position: fixed;
	z-index: 2;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.6);
}

.notify-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.notify-data {
	margin: auto;
	width: 80%;
	max-width: 600px;
	color: white;
	font-family: Play;
	box-shadow: 0px 0px 10px #000000;
}

.notify-top {
  font-family: Play;
	color: white;
	font-size: 28px;
}

.notify-close {
  user-select: none;
	color: white;
  float: right;
	font-weight: bold;
  font-size: 24px;
}

.notify-close:hover,
.notify-close:focus {
	color: #00bf00;
	text-decoration: none;
	cursor: pointer;
}

.consent-header {
  font-size: 24px;
  text-align: left;
}

.consent-checkbox {
  width: 24px;
  height: 24px;
  margin: 8px 8px 8px 8px;
}

.consent-title {
  font-size: 24px;
}

.consent-notify {
  position: fixed;
  bottom: 74px;
  left: 16px;
  max-width: 500px;
}

.consent-notify .cont-div {
  width: 100%;
  height: 100%;
  padding: 24px;
}

.terminal {
  height: 500px;
  overflow-y: auto;
  background-color: #111;
  color: #ddd;
  padding: 35px 45px;
  font-size: 14px;
  line-height: 1.42;
  font-family: 'IBM Plex Mono', Consolas, Menlo, Monaco, 'Courier New', Courier,
  monospace;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  text-align: left;
}

.terminal-line {
  line-height: 2;
  white-space: pre-wrap;
}

.terminal-prompt {
  line-height: 2;
  display: flex;
  align-items: center;
}

.terminal-prompt-label {
  flex: 0 0 auto;
  color: #55dd55;
}

.terminal-prompt-label-history {
  flex: 1;
  color: white;
  margin-left: 1rem;
}

.terminal-prompt-input {
  flex: 1;
  margin-left: 1rem;
  align-items: center;
  color: white;
}

.terminal-prompt-input input {
  flex: 1;
  width: 100%;
  padding: 0px;
  background-color: transparent;
  color: white;
  border: 0;
  outline: none;
  font-size: 14px;
  line-height: 1.42;
  font-family: 'IBM Plex Mono', Consolas, Menlo, Monaco, 'Courier New', Courier,
  monospace;
}

@media only screen and (max-width: 1000px) {
  .page-container {
    margin: 0px 12px 0px 12px;
  }
  .navbar-container {
    background-color: rgba(0, 100, 0, 0.9);
    backdrop-filter: none;
  }
	.navbar-items {
		flex-flow: column;
    align-items: baseline;
	}
  .navbar-links {
    background-color: #003200;
    width: 100%;
		flex-flow: column;
    align-items: baseline;
	}
  .navbar-item {
    width: 100%;
  }
  .navbar-link {
    width: 100%;
  }
  .navbar-link-login {
    width: 100%;
  }
  .navbar-item:hover .navbar-drop {
    display: none;
    opacity: 0;
  }
  .navbar-item-login {
    border-left: 0px;
    padding-left: 0px;
    margin: 0px;
    width: 100%;
    max-width: none;
  }
  .navbar-item-login-text {
    margin: 0px;
  }
  .col-cont {
    display: flex;
    flex-direction: column-reverse;
  }
  .col-1 {
    min-width: 100%;
    margin: 12px 0px 0px 0px;
  }
  .col-2 {
    min-width: 100%;
    margin: 0px 0px 12px 0px;
  }
  .col-buttons {
    flex-flow: column;
    align-items: center;
  }
  .stats-bar {
    height: 72px;
    flex-flow: column;
  }
  .cards-item {
    width: 100%;
  }
  .featured-news-item {
    flex-direction: column;
    justify-content: normal;
    align-items: unset;
    height: 393px;
  }
  .featured-news-left {
    max-width: unset;
    flex-basis: unset;
  }
  .featured-news-right {
    width: 90%;
    align-self: center;
    flex-basis: unset;
    max-width: 400px;
  }
  .mod-details {
    flex-direction: column;
    justify-content: normal;
    align-items: unset;
  }
  .mod-details-left {
    max-width: unset;
    flex-basis: unset;
  }
  .mod-details-right {
    max-width: unset;
    flex-basis: unset;
    align-self: unset;
  }
  .footer-left {
    display: none;
  }
  .footer-right {
    display: none;
  }
}